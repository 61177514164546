<template>
    <div class="home img-home login-bg-img">
        <div class="my-flex p5vm">
            <div class="content-block">
                <div class="login">
                    <img
                        class="logo"
                        src="./../../../public/img/logo2.png"
                        alt=""
                    />
                    <el-form :model="form" :rules="rules" ref="form">
                        <div class="block-1">
                            <div class="block-2">
                                <el-form-item>
                                    <el-input
                                        @keyup.enter.native="login()"
                                        type="text"
                                        placeholder="+998"
                                        v-model="form.phone"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-input
                                        @keyup.enter.native="login()"
                                        type="password"
                                        :placeholder="$t('message.password')"
                                        v-model="form.password"
                                    ></el-input>
                                </el-form-item>
                            </div>
                            <img
                                src="./../../../public/img/fingerprint.png"
                                alt=""
                                class="a-cod"
                            />
                        </div>

                        <el-form-item>
                            <el-button @click="login()" :loading="loading" type="success" class="btn-100">
                                {{ $t('message.Login') }}
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
export default {
  name: "login",
  data() {
    return {
      form: {
        phone: "+998",
        password: ""
      },
      rules: {
          phone: [
            { required: true, message: 'Пожалуйста, введите телефон ', trigger: 'blur' }            
          ],
          password: [
              { required: true, message: 'Пожалуйста, введите пароль', trigger: 'blur'}              
          ]
      },
      redirect: undefined,
      otherQuery: {},
      loading: false,
    };
  },
  mounted() {
    
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions({
        postLogin : 'auth/login'
    }),

    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },

    login() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
            this.loading = true;
            this.postLogin(this.form)
                .then(res=> {
                    this.loading = false;
                    this.$router.push({
                        path: this.redirect || "/",
                        query: this.otherQuery
                    });                
                })
                .catch(err => {
                    this.loading = false;                    
                    this.$alert(err);
                });
        }
      });
    },
  },
};
</script>

